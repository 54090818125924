<!--
 * @Description: 选择角色 - 班级
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-25 09:46:26
 * @LastEditors:
 * @LastEditTime: 2023-04-25 09:46:26
-->
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white relative overflow-hidden" v-loading="loading">
    <!-- Decorative background image and gradient -->
    <div aria-hidden="true" class="absolute inset-0">
      <div class="absolute inset-0 max-w-7xl mx-auto overflow-hidden xl:px-8">
        <img
          src="@/assets/home-page-02-sale-full-width.jpg"
          alt=""
          class="w-full h-full object-center object-cover"
        />
      </div>
      <div class="absolute inset-0 bg-white bg-opacity-75"></div>
      <div class="absolute inset-0 bg-gradient-to-t from-white via-white"></div>
    </div>

    <!-- Callout -->
    <section
      aria-labelledby="sale-heading"
      class="
        relative
        max-w-7xl
        mx-auto
        pt-32
        px-4
        flex flex-col
        items-center
        text-center
        sm:px-6
        lg:px-8
      "
    >
      <div class="max-w-2xl mx-auto lg:max-w-none">
        <button
          @click="handleSelectRole('admin')"
          class="
            mt-6
            inline-block
            w-full
            bg-indigo-500
            border border-transparent
            rounded-md
            py-3
            px-8
            mx-4
            font-medium
            text-white
            hover:bg-indigo-800
            sm:w-auto
          "
        >
          进入后台管理
        </button>

        <button
          @click="handleSelectRole('teacher')"
          class="
            mt-6
            inline-block
            w-full
            bg-gray-500
            border border-transparent
            rounded-md
            py-3
            px-8
            mx-4
            font-medium
            text-white
            hover:bg-gray-800
            sm:w-auto
          "
        >
          进入教师管理
        </button>
      </div>
    </section>
    <modifyPassword ref="modify"></modifyPassword>
  </div>
</template>
<script>
import { roleKey } from "@/settings.js";
import modifyPassword from "@/pages/select/modifyPassword.vue";
export default {
  name: "",
  components: { modifyPassword },
  props: {},
  data() {
    return {
      loading:false,
    };
  },
  created() {},
  async mounted() {
    await this.getInfo();
  },
  methods: {
    /**
     *  @actions:  登录信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 22:16
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getInfo() {
      this.loading = true
      await this.$cloud.get("/login/info").then((res) => {
        if (res.editPassword === "NO") {
          // 强制修改初始密码
          this.$refs["modify"].open("teacher");
        }
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
        this.$message.error(err)
      })
    },

    // 选择角色
    handleSelectRole(role) {
      if (role === "admin") {
        this.$cloud.go("/pages/index/index");
      } else {
        this.$cloud.go("/pages/home/home");
      }
      localStorage.setItem(roleKey, role);
    },
  },
};
</script>
<style lang="scss" scoped></style>
